.drawn-numbers {
    &__table {
        margin: auto;
    }

    &__number {
        border-radius: 50%;
        border: 1px solid Black;
        height: 25px;
        width: 25px;
        margin: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        transition: all .2s ease-out;
    }
}

