.app {
    background-image: linear-gradient(120deg, #fda085 0%, #f6d365 100%);
    min-height: 100vh;
    position: relative;
    z-index: 0;
}

.page {
    padding: 50px 0;

    &__header {
        color: white;
        font-weight: bold;
        margin-bottom: 20px;
        padding-left: 15px;
    }
}

.card {
    border: none;
    border-radius: 20px;
    position: relative;
    margin-bottom: 30px;

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
        0 16px 16px rgba(0, 0, 0, 0.12);

    &__menu {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        margin-top: 10px;   
    }
}

.list {
    list-style-type: none;
    padding: 0;

    &__item {
        border-top: 1px solid #ddd;

        &:first-child {
            border-top: none;
        }

        &__link {
            display: flex;
            align-items: center;
            min-height: 50px;
            color: inherit;
            

            
        }

        > a {
            transition: 0.2s all ease-out;
            &:hover {
                color: inherit;
                text-decoration: none;
                background-color: #ddd;
            }
        }

        &__icon {
            padding-right: 10px;
        }

        &__prefix {
            color: #777;
            padding-right: 5px;
        }

        &__title {
            font-weight: 700;
        }
    }

    &__empty {
        font-style: italic;
        color: #777;
    }
}

// card-claim-list__item__title__username
.card-claim-list {
    &__item {
        &__title {
            display: flex;
            flex-flow: column;
            justify-content: center;
            height: 60px;

            &__email {
                font-weight: 400;
                font-size: 14px;
                color: #777;
            }   
        }
    }
}

.btn {
    border-radius: 20px;

    &-light {
        background-color: #eee;
        border-color: #eee;
    }

    &-primary {
        background-color: #fda085;
        border-color: #fda085;

        &:hover {
            background-color: #e6937c;
            border-color: #e6937c;
        }

        &:not(:disabled):not(.disabled):active, &:focus {
            background-color: #d48772;
            border-color: #d48772;
            box-shadow: 0 0 0 0.2rem rgba(212, 135, 114, 0.5);
        }

        &.disabled, &:disabled {
            background-color: #fcc0af;
            border-color: #fcc0af;
            cursor: not-allowed;
        }
    }
}

.text-underline {
    text-decoration: underline;
}

.form {
    &__error {
        color: #df4f4f;
    }
}