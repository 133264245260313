
#root-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;  
    visibility: hidden;
}

.nohns-modal {
    position: relative;
    z-index: 100;
    background: #fff;
    border-radius: 10px;
    min-width: 450px;
    padding: 15px;
    visibility: visible;

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0);
        visibility: hidden;
        transition: .2s all ease-out;

        &.open {
            background: rgba(0,0,0,0.7);
            visibility: visible;
            z-index: 1;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;

        .btn {
            color: #777;
            box-shadow: none !important;

            &:hover {
                color: #000;
            }
        }
    }

    &__header {
        margin-bottom: 20px;
    }

    &__buttons {
        float: right;
        position: relative;
        right: 0;
        bottom: 0;

        > * {
            margin-left: 10px;
        }
    }

    &__status {
        float: left;
        position: relative;
        left: 0;
        bottom: 0;
    }
}

.check-winner-modal {
    
    &__table {
        table, th, td {
            padding: 0;
            border: 1px solid black;
        }
    }

    &__number {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}